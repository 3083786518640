/**
 * Template padrão de tabela dinâmica do sistema.
 *
 * @param  {String} title - Título do componente (opcional, mas desejável).
 * @param  {String} subtitle - Subtítulo do componente (opcional).
 * @param  {Object[]} categories - Categorias da tabela e campos a serem populados. Os campos podem ser ordenados pela posição no objeto e somente os campos escolhidos serão exibidos.
 * @param  {String} categories.title - Nome da coluna a ser exibido na lista. Se o campo for vazio (`title: ''`), o dado será enviado de forma oculta.
 * @param  {String} categories.field - Valor que ativa o filtro da categoria escolhida. Exemplo: `e.code` (onde `e` é padrão para a filtragem).
 * @param  {String} categories.value - Valor do campo que vem da API para vincular à categoria criada.
 * @param  {String} categories.type - Estilo de exibição da chave (exemplo: `label` para estilo tag).
 * @param  {String} categories.defaultValue - Valor padrão de exibição quando a API retorna `null/undefined`. Também pode ser usado com valor vazio, como no caso de "Ações", para não exibir nada na coluna.
 * @param  {String} categories.link - Adiciona link ao campo, o endereço será o endpoint escolhido (exemplo: `/comex/process/detail/${identifier}`). O `identifier` pode ser detectado automaticamente ou definido com `identifierKey`.
 * @param  {String} categories.align - Alinhamento do item específico na tabela.
 * @param  {String} categories.tooltip - Aplica uma tooltip com descrição na categoria.
 * @param  {Boolean} categories.count - Aplica a contagem de itens da categoria.
 * @param  {Boolean} categories.renderHTML - Renderiza HTML no valor da categoria.
 * @param  {String} categories.variantTag - Aplica estilização predefinida na `statusPreset` (exemplo: `softDashed`, padrão: `solidFlat`).
 * @param  {Object[]} group - Marca os grupos na tabela.
 * @param  {String} group.name - Título do grupo (opcional, mas desejável).
 * @param  {String} group.color - Cor usada para marcar o item do grupo (opcional).
 *
 * @param  {Object[]} subCategories - Subcategorias da tabela, segue os mesmos conceitos de `categories`.
 * @param  {String} mainCategoryMobile - Categoria principal exibida apenas no mobile (opcional, exemplo: `codeReadable`).
 * @param  {Boolean} hasCollapsed - Indica se a tabela possui um subnível (opcional).
 * @param  {Boolean} hasPagination - Indica se a tabela terá paginação (opcional, requer outras propriedades para funcionar).
 * @param  {Boolean} hasDetailsButton - Adiciona botão para editar/exibir itens da lista (opcional).
 * @param  {Boolean} hasDetailsButtonSublist - Adiciona botão para editar/exibir itens da sublista (opcional).
 * @param  {String} detailsRedirectEndpoint - Endpoint para redirecionamento do botão de detalhes (opcional, exemplo: `/vendemmia/ncm-management/detail/${identifier}`).
 * @param  {String} detailsRedirectEndpointSublist - Endpoint para redirecionamento do botão de detalhes na sublista (opcional).
 * @param  {Boolean} hasDeleteButton - Adiciona botão para remover itens da lista (opcional, requer `deleteButtonAction`).
 * @param  {Function} deleteButtonAction - Ação executada ao clicar no botão de exclusão (opcional).
 * @param  {Object[]} customButtons - Gera uma lista de botões personalizados (opcional).
 * @param  {String} customButtons.label - Nome do botão personalizado a ser exibido.
 * @param  {String} customButtons.tooltip - Tooltip de descrição do botão.
 * @param  {IconType} customButtons.icon - Ícone exibido no botão (opcional).
 * @param  {Function|Boolean} customButtons.isButtonVisible - Método para exibir/ocultar o botão (exemplo: `(item) => item.status === 'PUBLISHED'`).
 * @param  {Function|Boolean} customButtons.isButtonDisabled - Método para habilitar/desabilitar o botão (exemplo: `(item) => item.status !== 'RASCUNHO'`).
 * @param  {Function} customButtons.action - Ação executada ao clicar no botão.
 * @param  {Object[]} customButtons.allowedRoles - Permissões que habilitam a exibição do botão (exemplo: `['ROLE_SYSTEM_ADMIN']`).
 * @param  {Object[]} customButtons.deniedRoles - Permissões que desabilitam a exibição do botão (exemplo: `['ROLE_CLIENT']`).
 *
 * @param  {Object[]} customFields - Gera uma lista de campos personalizados (opcional).
 * @param  {String} customFields.customFieldstype - Tipo de campo (`select | radio`).
 * @param  {String} customFields.name - Chave recebida da API para popular o valor dos inputs.
 * @param  {Function} customFields.action - Ação a ser realizada ao clicar no input, retorna `identifier` e `currentFilter`.
 * @param  {Object[]} customFields.options - Lista de opções para o select (valores: `label`, `value`, `slug`).
 * @param  {Object[]} customFields.roles - Regras para determinar valores true/false em radio (exemplo: `{ label: 'Ativado', condition: 'Active', status: true }`).
 * @param  {Object} customFields.bgColor - Cores de background para inputs radio (exemplo: `{ turnIn: '#6BDD9E', turnOff: '#E74C3C' }`).
 *
 * @param  {String} collapsedContentType - Template da sublista (opcional, tipos: `table` ou `timeline`).
 * @param  {Icon} collapsedIcon - Ícone personalizado para o colapso da tabela (opcional).
 * @param  {Array} tags - Define quais valores receberão estilização de status (opcional).
 * @param  {Object[]} statusPreset - Configuração de cores de status para tags (opcional).
 * @param  {String} statusPreset.color - Cor da tag.
 * @param  {String} statusPreset.label - Nome exibido na tag.
 * @param  {String} statusPreset.value - Valor do campo vindo da API que será associado à tag.
 *
 * @param  {Array} images - Define quais valores receberão a estilização de imagens (opcional).
 * @param  {Object[]} imagePreset - Configuração de exibição de imagens (opcional).
 * @param  {String} imagePreset.image - Endereço da imagem exibida no lugar do texto.
 * @param  {String} imagePreset.value - Valor do campo vindo da API que será associado à imagem.
 *
 * @param  {Object[]} rawData - Dados brutos da API (obrigatório).
 * @param  {Object[]} list - Lista de itens a serem exibidos (obrigatório).
 * @param  {Object[]} subList - Sublista de itens (opcional).
 * @param  {String} identifierKey - Chave personalizada da lista, caso diferente de `identifier` ou `processIdentifier`.
 * @param  {String} pathSublist - Caminho da sublista (opcional, exemplo: `data.notes`).
 * @param  {String} emptyListMessage - Mensagem exibida quando a lista estiver vazia.
 * @param  {Number} maxLengthResume - Limite de caracteres no resumo do texto.
 * @param  {Number} maxLengthResumeChildren - Limite de caracteres no resumo do texto da sublista.
 * @param  {String} cellRowSize - Largura das células das colunas.
 * @param  {String} resizeRows - Personaliza a largura das células do primeiro nível da lista.
 * @param  {String} resizeRowsChildren - Personaliza a largura das células do segundo nível da lista.
 *
 * @param  {State} action - Ação da paginação (necessário para `hasPagination`).
 * @param  {State} sorting - Ordenação da lista pelo cabeçalho (necessário para filtros em `categories.field`).
 * @param  {State} metadata - Metadados da paginação (necessário para `hasPagination`).
 * @param  {SetState} setAction - Atualiza a ação da paginação (necessário para `hasPagination`).
 * @param  {SetState} setSorting - Atualiza a ordenação da lista (necessário para filtros).
 * @param  {SetState} setMetadata - Atualiza os metadados da paginação (necessário para `hasPagination`).
 * @param  {Function} handleSort - Método para atualizar a paginação com o filtro escolhido.
 */

import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Card,
  Flex,
  Heading,
  List,
  ListItem,
  Text,
} from '@chakra-ui/react';

import Paginate from '../Paginate/Paginate';

import CommonListCategories from './CommonListCategories';
import CommonListItemContent from './CommonListItemContent';
import CommonListStyles from './CommonListStyles';
import { SkeletonContent } from './components/SkeletonContent';
import { CommonListProvider } from './contexts/CommonListContext';
import { useCommonListProps } from './Hooks/useCommonListProps';

const CommonList = ({ children, ...props }) => {
  const {
    list,
    categories,
    isMobile,
    mainCategoryMobile,
    setMetadata,
    setAction,
    action,
    metadata,
    hasPagination,
    hasPages,
    title,
    subtitle,
    identifier,
    formattedList,
    emptyListMessage,
    resizeRows,
    gridTemplateLength,
    isLoaded,
    isLoading,
  } = useCommonListProps({ ...props, children });

  console.log('isLoaded: ', isLoaded, 'isLoading: ', isLoading, hasPages);

  return (
    <Card m="10px" border={'1px solid #70707036'}>
      <CommonListProvider {...props}>
        {/* <ExpandableRowContent props={{ ...props, children }} /> */}
        <Box overflow={'auto'}>
          {title && <Heading>{title}</Heading>}
          {subtitle && <Text as={'h3'}>{subtitle}</Text>}
          <CommonListStyles />
          <Flex m={'15px'} flexDirection={'column'} gap={'20px'} width={{ md: 'fit-content' }} minW={{ md: 'calc(100% - 30px)' }}>
            <CommonListCategories categories={categories} gridTemplateLength={resizeRows ?? gridTemplateLength} />
            <List display={'flex'} flexDirection={'column'} gap={'10px'}>
              {/* carregamento do skeleton */}
              {isLoading ? (
                <SkeletonContent />
              ) : isMobile ? (
                <Accordion display={'flex'} gap={'15px'} flexDirection={'column'} isMobile={isMobile}>
                  {formattedList?.map((item, key) => (
                    <AccordionItem
                      key={key}
                      border={'1px solid #70707036'}
                      borderRadius={'10px'}
                      columnGap={'15px'}
                      p={'0 10px'}
                      lineHeight={'40px'}
                      boxShadow="sm">
                      <AccordionButton>
                        <Box as="span" flex="1" textAlign="left">
                          {mainCategoryMobile === (null || '' || undefined)
                            ? Object.entries(item)[0][1]
                            : item.hasOwnProperty(mainCategoryMobile) && item[mainCategoryMobile] !== ''
                            ? item[mainCategoryMobile]
                            : '-'}
                        </Box>
                        <AccordionIcon />
                      </AccordionButton>
                      <AccordionPanel padding={'10px 0'}>
                        <CommonListItemContent
                          item={item}
                          listIndex={key}
                          content={children}
                          rawItem={list[key]}
                          identifier={identifier[key]}
                        />
                      </AccordionPanel>
                    </AccordionItem>
                  ))}
                </Accordion>
              ) : formattedList && formattedList.length > 0 ? (
                formattedList.map((item, key) => (
                  <ListItem key={key}>
                    <CommonListItemContent
                      item={item}
                      listIndex={key}
                      content={children}
                      rawItem={list[key]}
                      identifier={identifier[key]}
                    />
                  </ListItem>
                ))
              ) : (
                <Text alignSelf={'center'}>{emptyListMessage}</Text>
              )}
            </List>
          </Flex>
        </Box>
        {hasPagination && hasPages && (
          <Paginate metadata={metadata} setMetadata={setMetadata} action={action} setAction={setAction} showDetails={true} />
        )}
      </CommonListProvider>
    </Card>
  );
};

export default CommonList;
