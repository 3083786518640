import { ResponsiveBar } from '@nivo/bar';

export const CommonBarChart = ({ props }) => {
  const { data, label, indexBy, colors = { scheme: 'nivo' }, tooltip, ...customProps } = props;

  // Configurações padrão
  const defaultProps = {
    margin: { top: 50, right: 60, bottom: 80, left: 120 },
    padding: 0.6,
    valueScale: { type: 'linear' },
    indexScale: { type: 'band', round: true },
    colors,
    defs: [
      {
        id: 'dots',
        type: 'patternDots',
        background: 'inherit',
        color: '#422C76',
        size: 4,
        padding: 1,
        stagger: true,
      },
      {
        id: 'lines',
        type: 'patternLines',
        background: 'inherit',
        color: '#eed312',
        rotation: -45,
        lineWidth: 6,
        spacing: 10,
      },
    ],
    fill: [
      {
        match: { id: 'fries' },
        id: 'dots',
      },
      {
        match: { id: 'sandwich' },
        id: 'lines',
      },
    ],
    borderColor: {
      from: 'color',
      modifiers: [['darker', 1.6]],
    },
    axisTop: null,
    axisRight: null,
    axisLeft: {
      tickSize: 5,
      tickPadding: -5,
      tickRotation: 0,
      legend: 'Disponível em estoque',
      legendPosition: 'middle',
      legendOffset: -60,
    },
    enableLabel: false,
    enableTotals: true,
    totalsOffset: 12,
    labelSkipWidth: 12,
    labelSkipHeight: 12,
    labelTextColor: {
      from: 'color',
      modifiers: [['darker', 1.9]],
    },
    theme: {
      fontFamily: 'Hanken Grotesk',
      fontSize: 14,
      axis: {
        ticks: {
          text: { fontSize: 14, fill: '#333333' },
        },
        legend: {
          text: { fontSize: 16, fill: '#555555' },
        },
      },
      legends: {
        text: { fontSize: 14, fill: '#444444' },
      },
      labels: {
        text: { fontSize: 16, fill: '#FFFFFF' },
      },
    },
    role: 'application',
    ariaLabel: 'Nivo bar chart demo',
    tooltip,
  };

  // Combinação de props
  const finalProps = {
    ...defaultProps, // Props padrão
    data, // Props principais do gráfico
    keys: label,
    indexBy,
    ...customProps, // Sobrescreve padrões com customizações do usuário
  };

  return <ResponsiveBar {...finalProps} />;
};
