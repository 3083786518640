import React from 'react';

import { FieldArray, useFormikContext } from 'formik';
import { FaTrashAlt } from 'react-icons/fa';
import { MdAdd } from 'react-icons/md';

import { Box, Button, Divider, Grid, Tooltip } from '@chakra-ui/react';

import { DynamicAddressAutocomplete } from '../../../../components/CommonForm/Fields/DynamicAddressAutocomplete';
import { DynamicInputField } from '../../../../components/CommonForm/Fields/DynamicInputField';
import { renderContactFields } from '../Helpers/renderContactFields';

export const DynamicFormSupplier = () => {
  const { values, setFieldValue } = useFormikContext();

  // Garante que 'contacts' seja um array
  const contacts = values.contacts || [];
  const hasContacts = contacts.length > 0;

  // Função para adicionar um novo contato
  const addNewContact = () => {
    const newContact = { name: '', email: '' };
    setFieldValue('contacts', [...contacts, newContact]);
  };

  // Função para remover um contato pelo índice
  const removeContact = (index) => {
    setFieldValue(
      'contacts',
      contacts.filter((_, i) => i !== index)
    );
  };

  return (
    <Grid borderWidth="1px" borderRadius="md" borderColor={'#70707036'} gap={3} p={4} mb={4}>
      <Grid gridTemplateColumns="1fr 1fr" columnGap="40px">
        <DynamicInputField props={{ name: 'documentNumber', placeholder: 'CNPJ do fornecedor', mask: 'cnpj' }} />
        <DynamicInputField props={{ name: 'name', placeholder: 'Nome do fornecedor' }} />
      </Grid>

      <DynamicAddressAutocomplete props={{ name: 'address', placeholder: 'Digite o endereço' }} />
      {hasContacts && <Divider m={'20px 0'} />}

      <FieldArray
        name="contacts"
        render={() => (
          <>
            {contacts.map((contact, index) => (
              <Grid gridTemplateColumns={'1fr 80px'} key={index} alignItems="end">
                {/* Renderiza campos de contato */}
                {renderContactFields(index)}

                {/* Botão de Remover Contato */}
                <Button
                  variant="remove"
                  type="button"
                  onClick={() => removeContact(index)}
                  ml={2}
                  minH={'47px'}
                  size="sm"
                  colorScheme="red"
                  mb={'16px'}>
                  <Tooltip label="Remover contato" fontSize="md">
                    <Box>
                      <FaTrashAlt color="#ec7063" size={16} />
                    </Box>
                  </Tooltip>
                </Button>
              </Grid>
            ))}
            <Button variant="add" type="button" maxW="216px" onClick={addNewContact}>
              <MdAdd /> Adicionar contato
            </Button>
          </>
        )}
      />
    </Grid>
  );
};
