import { useState } from 'react';

import { Box, Flex, Grid, Text } from '@chakra-ui/layout';

import Card from '../../../components/Card/Card';
import TotalProducts from '../../../components/Charts/Warehouse/TotalProducts';
import { CommonBarChart } from '../../../components/CommonChats/CommonBarChart';
import CommonList from '../../../components/CommonList/CommonList';
import { commonListMethods } from '../../../components/CommonList/methods/commonListMethods';
import Page from '../../../components/Page';
import ScreenLoader from '../../../components/ScreenLoader/ScreenLoader';
import { useForceRefresh } from '../../../hooks/useForceRefresh';
import permissions from '../../../services/permissions';
import requests from '../../../services/requests';
import { filterOptionsStockTurnover } from '../../../utils/filters/filterPresets';
import { executeRequest } from '../../../utils/requests/executeRequest';
import { formatToTwoDecimalPlaces } from '../../../utils/text/format/formatToTwoDecimalPlaces';

import { commonListConfig } from './components/commonListConfig';

export const StockTurnoverPage = () => {
  // Permissions
  const hasPermission = permissions.warehouseStockTurnover;
  const hasPermissionWarehouse = permissions.warehouse;

  // Instances
  const [list, setList] = useState([]);
  const [sublist, setSublist] = useState();
  const [totalProducts, setTotalProducts] = useState();
  const [stockByPeriod, setStockByPeriod] = useState([]);
  const [filterOptions, setFilterOptions] = useState([]);

  const [isLoadingChart, setIsLoadingChart] = useState(true);

  // Paginate commonList
  const { action, setAction, handleSort, metadata, setMetadata, isLoading, setIsLoading } = commonListMethods();

  // Requests
  const getStockTurnover = async (filters, page) => {
    const res = await executeRequest({
      action: () => requests.fetchStockTurnover(filters, page),
      setIsLoading,
    });

    //formattedAtDecimalPlaces
    const formattedTurnoverList = res.data.data.map((item) => {
      return { ...item, stockTurnover: formatToTwoDecimalPlaces(item.stockTurnover) };
    });

    setList(formattedTurnoverList);
    setSublist(formattedTurnoverList);
    setMetadata(res.data.meta);
  };

  const getStockTurnoverMetrics = async (filters, page) => {
    const res = await executeRequest({
      action: () => requests.fetchStockTurnoverMetrics(filters, page),
      setIsLoading: setIsLoadingChart,
    });

    setTotalProducts(res.data);
    setStockByPeriod(
      res.data.byPeriod
        .map((item) => ({
          mount: `${item.month}\n${item.year}`,
          total: parseInt(item.total, 10), // Converte para número, caso seja necessário
        }))
        .reverse() // Inverte a ordem dos itens
    );
  };

  const getFilterOptions = async () => {
    setFilterOptions(await filterOptionsStockTurnover());
  };

  const load = (filters, _key, page) => {
    getFilterOptions();
    getStockTurnover(filters, page);
    getStockTurnoverMetrics(filters, page);
  };

  // Custom hook for refresh
  const { forceLoadTrigger, triggerRefresh } = useForceRefresh(load);

  return (
    <Page
      screen="stockTurnover"
      title="Giro de estoque"
      breadcrumbs={[{ link: '#', title: 'Armazém' }]}
      textFilterPlaceholder="processo, empresa ou ref"
      hasPermission={hasPermission}
      list={list}
      metadata={metadata}
      load={load}
      filterOptions={filterOptions}
      isContentLoading={isLoading}
      isRefreshLoading={isLoading}
      forceLoadTrigger={forceLoadTrigger}
      showFilters={true}
      allowEmptySearchPeriod={true}
      showPeriodFilter={true}>
      <Grid templateRows={'480px 1fr'} gap={'20px'} m={'10px'}>
        <Grid templateColumns={'615px 1fr'} gap={'10px'}>
          <Card w="full" title={'Total de Produtos'}>
            <ScreenLoader isLoading={isLoadingChart}>
              <TotalProducts data={totalProducts} />
            </ScreenLoader>
          </Card>
          <Card w="full" title={'Estoque disponível'}>
            <Flex justifyContent={'flex-end'} gap={'35px'} p={'15px 35px 0 20px'}>
              <Flex gap={'10px'} alignItems={'center'}>
                <Box bg={'#422C76'} borderRadius={'3px'} w={'30px'} h={'19px'} display={'block'}></Box>
                <Text color={'#0F0A1D'} size={'13px'}>
                  Meses anteriores
                </Text>
              </Flex>
              <Flex gap={'10px'} alignItems={'center'}>
                <Box bg={'#C767DC'} borderRadius={'3px'} w={'30px'} h={'19px'} display={'block'}></Box>
                <Text color={'#0F0A1D'} size={'13px'}>
                  Mês vigente
                </Text>
              </Flex>
            </Flex>
            <ScreenLoader isLoading={isLoadingChart}>
              <CommonBarChart
                props={{
                  data: stockByPeriod,
                  label: ['total'],
                  indexBy: 'mount',
                  colors: (bar) => {
                    // Verifica se a barra é a última
                    const isLastBar = bar.index === stockByPeriod.length - 1;
                    return isLastBar ? '#C767DC' : '#422C76'; // Cor personalizada para a última barra
                  },
                  tooltip: (e) => {
                    const { total } = e.data; // Acessando as informações do mês, ano e total

                    return (
                      <Card p={'10px 22px'}>
                        <Text color={'#6C48C2'} fontSize={'12px'}>
                          {e.indexValue}
                        </Text>{' '}
                        <Text color={'#B7B7B7'} fontSize={'12px'} w={'77px'} lineHeight={'18px'}>
                          Disponivel em estoque:
                        </Text>
                        <Text color={'#707070'} fontSize={'17px'}>
                          {total}
                        </Text>
                      </Card>
                    );
                  },
                }}
              />
            </ScreenLoader>
          </Card>
        </Grid>
      </Grid>
      <CommonList
        //lists
        list={list}
        rawData={list}
        subList={sublist}
        //actions
        action={action}
        /* sorting={sorting} */
        metadata={metadata}
        setAction={setAction}
        setMetadata={setMetadata}
        handleSort={handleSort}
        //loading
        isLoading={isLoading}
        //props
        {...commonListConfig}
      />
    </Page>
  );
};
