import React, { useEffect, useState } from 'react';

import Select from 'react-select';

import { Button, Flex, FormControl, Grid, Text } from '@chakra-ui/react';

import InputDefault from '../../../../../components/Form/Input/InputDefault';
import { LabelDefault } from '../../../../../components/Form/Label/LabelDefault';
import requests from '../../../../../services/requests';

export const CardGeneralInformation = ({
  values,
  setFieldValue,
  errors,
  setFieldError,
  validatedForm,
  setStep,
  listVehicles,
  vehicles,
}) => {
  const [drivers, setDrivers] = useState([]);

  const callListDrivers = () => {
    requests
      .listDriversAll(
        {
          filters: {
            selectedStatus: ['ATIVO'],
            role: ['ROLE_DRIVER'],
          },
        },
        0,
        99999
      )
      .then((list) => {
        let options = [];
        list.data.map((driver) => {
          return options.push({
            value: driver.identifier,
            label: driver.name,
          });
        });
        setDrivers(options);
      });
  };

  const onSubmit = async () => {
    if (await validatedForm(values, setFieldError, 'informationSchema')) setStep(4);
  };

  useEffect(() => {
    callListDrivers();
    listVehicles();
  }, []);

  return (
    <>
      <Grid templateColumns={{ sm: '1fr', md: 'repeat(2, 1fr)' }} gap="15px" mx="2px">
        <FormControl>
          <LabelDefault
            name="information.driver"
            text={
              <Flex gap="4px" alignItems="center">
                <Text>Motorista</Text>
                <Text fontSize="small"> (opcional)</Text>
              </Flex>
            }
          />
          <Select
            width={{ base: '7rem', md: '8.125rem' }}
            id="information.driver"
            name="information.driver"
            value={values.information.driver}
            // value={drivers?.filter((option) => option.value === data.driver)}
            options={drivers}
            onChange={(option) => setFieldValue('information.driver', option)}
            className="input-register"
            placeholder="Selecione"
          />
        </FormControl>

        <FormControl>
          <LabelDefault
            name="information.vehicle"
            text={
              <Flex gap="4px" alignItems="center">
                <Text>Veículo</Text>
                <Text fontSize="small"> (opcional)</Text>
              </Flex>
            }
          />
          <Select
            width={{ base: '7rem', md: '8.125rem' }}
            id="information.vehicle"
            name="information.vehicle"
            value={values.information.vehicle}
            options={vehicles}
            isMulti={true}
            onChange={(option) => setFieldValue('information.vehicle', option)}
            className="input-register"
            placeholder="Selecione"
          />
        </FormControl>

        <FormControl>
          <LabelDefault name="information.startsAt" text="Data e horário inicial" />
          <InputDefault
            className="input-register"
            type="datetime-local"
            cursor="pointer"
            placeholder="Data e horário final"
            _placeholder={{ color: '#0F0A1D66' }}
            name="information.startsAt"
            background="transparent"
            setFieldValue={setFieldValue}
            value={values.information.startsAt}
          />
          {errors?.startsAt && <Text className="error-message-error">{errors.startsAt}</Text>}
        </FormControl>

        <FormControl>
          <LabelDefault name="information.endsAt" text="Data e horário final" />
          <InputDefault
            className="input-register"
            type="datetime-local"
            cursor="pointer"
            placeholder="Data e horário final"
            _placeholder={{ color: '#0F0A1D66' }}
            name="information.endsAt"
            background="transparent"
            setFieldValue={setFieldValue}
            value={values.information.endsAt}
          />
          {errors?.endsAt && <Text className="error-message-error">{errors.endsAt}</Text>}
        </FormControl>
      </Grid>

      <Button mt="20px" variant="primary" onClick={onSubmit}>
        <Text fontStyle="paragraph">{`Concluir`}</Text>
      </Button>
    </>
  );
};
export default CardGeneralInformation;
