import { generateLabelSelect } from '../../../../utils/translate';

export const initialValues = (entity = {}, listVehicles = []) => {
  let trip = [];
  let responsibles = [];
  let vehicles = [];

  if (entity?.platesList?.length > 0) {
    entity?.platesList.map((plate, index) => {
      const item = listVehicles?.find((item) => item.label === plate);
      if (item) {
        vehicles.push({ label: plate, value: item?.value });
      }
    });
  } else if (entity?.vehicle != undefined) {
    vehicles.push({ label: entity.vehicle.licensePlate, value: entity.vehicle.identifier });
  }

  if (entity?.responsibles?.length > 0) {
    entity?.responsibles.map((user, index) => {
      responsibles.push({ value: user?.responsible?.identifier, label: user?.name });
    });
  }

  if (entity?.trajectories?.length > 0) {
    entity?.trajectories.map((trajectory) => {
      trip.push({
        type: { value: trajectory.type, label: trajectory.type },
        cteNumber: trajectory?.cteFiles || '',
        address: trajectory.address || '',
        observation: trajectory.observation || '',
        latitude: trajectory.latitude || 0,
        longitude: trajectory.longitude || 0,
        clients: trajectory.clients || [],
      });
    });
  }

  return {
    main: {
      processes: entity?.processesList ? entity?.processesList : null,
      clients: null,
      driverFreightFiles: null,
      modality: entity?.modality ? { label: generateLabelSelect(entity.modality), value: entity.modality } : '',
      externalCode: entity?.externalCode || '',
      typeExternalCode: entity?.typeExternalCode
        ? { label: generateLabelSelect(entity.typeExternalCode), value: entity.typeExternalCode }
        : '',
      shippingCompanyName: entity?.shippingCompanyName
        ? entity?.shippingCompanyName != 'VENDEMMIA'
          ? { label: 'Outro', value: 'OUTRO' }
          : { label: generateLabelSelect(entity.shippingCompanyName), value: entity.shippingCompanyName }
        : '',
      shippingCompanyNameDescription: !!(entity?.shippingCompanyName && entity.shippingCompanyName !== 'VENDEMMIA')
        ? entity?.shippingCompanyName
        : '',
      typeTransport: entity?.typeTransport ? { label: generateLabelSelect(entity.typeTransport), value: entity.typeTransport } : '',
      subTypeTransport: entity?.subTypeTransport
        ? { label: generateLabelSelect(entity.subTypeTransport), value: entity.subTypeTransport }
        : '',
      typeMandatoryFile: entity?.typeMandatoryFile
        ? { label: generateLabelSelect(entity.typeMandatoryFile), value: entity.typeMandatoryFile }
        : '',
    },
    trip: trip,
    freight: {
      merchandiseValue: entity?.merchandiseValue || '',
      merchandiseDamagedValue: entity?.merchandiseDamagedValue || '',
      freightValue: entity?.freightValue || '',
      cubage: entity?.cubage || '',
      weight: entity?.weight || '',
    },
    information: {
      driver: entity?.userDriverIdentifier ? { label: entity?.userDriverName, value: entity.userDriverIdentifier } : '',
      vehicle: vehicles,
      startsAt: entity?.startsAt || '',
      endsAt: entity?.endsAt || '',
    },
  };
};
