const yup = require('yup');

const generalInformationValidation = () => {
  let validation = yup.object().shape({
    driver: yup.string(),
    vehicle: yup.string(),
    startsAt: yup.string().required('Campo obrigatório'),
    endsAt: yup.string().required('Campo obrigatório'),
  });

  return validation;
};

export default generalInformationValidation;
