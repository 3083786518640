import { AiFillWarning } from 'react-icons/ai';
import { BiCurrentLocation } from 'react-icons/bi';
import { MdPhonelinkRing, MdSettingsSuggest } from 'react-icons/md';

import { Flex, Icon } from '@chakra-ui/react';

export const convertColorUser = (status) => {
  let color;
  switch (status) {
    case 'ATIVO':
    case 'APROVADO':
      color = 'green';
      break;
    case 'INATIVO':
    case 'REPROVADO':
      color = 'red';
      break;
    case 'ANONIMIZADO':
      color = 'gray';
      break;
    default:
      color = 'yellow';
      break;
  }

  return color;
};

export const descriptionStatusUser = (status) => {
  let text;
  switch (status) {
    case 'EM_ANALISE':
      text = 'EM ANÁLISE';
      break;
    default:
      text = status;
      break;
  }

  return text;
};

export const descriptionTypeUser = (type) => {
  if (type == 'EXTERNAL') {
    return 'Parceiro externo';
  }

  return 'Colab. Vendemmia';
};

export const convertColorTrip = (status) => {
  switch (status) {
    case 'CREATED':
      return ['status-100'];
    case 'SENT_TO_DRIVER':
      return ['status-200'];
    case 'ACCEPTED_BY_DRIVER':
      return ['status-300'];
    case 'GOING_TO_COLLECT':
      return ['status-400'];
    case 'READ_TO_COLLECT':
      return ['status-500'];
    case 'COLLECTING':
      return ['status-600'];
    case 'READ_TO_TRAVEL':
      return ['status-700'];
    case 'TRAVELLING':
      return ['status-800'];
    case 'COMPLETED':
      return ['status-900'];
    // TODO
    case 'ARRIVED_AT_DESTINATION':
    case 'UNLOADING':
    case 'DELIVER_SIGNATURE_COLLECTED':
    case 'COMPLETED':
    case 'PAYMENT_ALLOWED':
    case 'PAID':
    case 'DELIVERED_WITH_SIGNATURE':
      return 'green';
    case 'REFUSED_BY_DRIVER_HANDLED':
    case 'HALTED':
    case 'HALTED_HANDLED':
    case 'CANCELLED':
    case 'DELIVERED_WITHOUT_SIGNATURE':
    case 'DELIVER_REFUSED':
    case 'PAID_CANCELLED':
      return 'red';
    default:
      return 'gray';
  }
};

export const descriptionStatusTrip = (status) => {
  switch (status) {
    case 'DRAFT':
      return 'Rascunho';
    case 'CREATED':
      return 'Cadastrada';
    case 'SENT_TO_DRIVER':
      return 'Pendente';
    case 'REFUSED_BY_DRIVER':
        return 'Duplicada';
    case 'REFUSED_BY_DRIVER_HANDLED':
      return 'Recusada';
    case 'ACCEPTED_BY_DRIVER':
      return 'Agendada';
    case 'GOING_TO_COLLECT':
      return 'Direcionando para local de coleta';
    case 'READ_TO_COLLECT':
      return 'Chegou para coleta';
    case 'COLLECTING':
      return 'Coleta iniciada';
    case 'READ_TO_TRAVEL':
      return 'Coleta terminada / Carregado';
    case 'TRAVELLING':
    case 'CTE_TRAVELLING':
      return 'Em viagem';
    case 'HALTED':
    case 'HALTED_HANDLED':
      return 'Viagem impedida';
    case 'ARRIVED_AT_DESTINATION':
      return 'Chegada no destino';
    case 'UNLOADING':
      return 'Início de descarregamento / Descarregando';
    case 'DELIVER_SIGNATURE_COLLECTED':
      return 'Recebimento assinado';
    case 'CTE_COMPLETED':
    case 'COMPLETED':
      return 'Finalizada';
    case 'PAYMENT_ALLOWED':
      return 'Pagamento liberado';
    case 'PAID':
      return 'Pagamento efetuado';
    case 'CANCELLED':
      return 'Cancelada';
    case 'DELIVERED_WITH_SIGNATURE':
      return 'Assinatura feita pelo motorista';
    case 'DELIVERED_WITHOUT_SIGNATURE':
      return 'Assinatura recusada pelo motorista';
    case 'DELIVER_REFUSED':
      return 'Viagem cancelada pelo motorista';
    case 'PAID_CANCELLED':
      return 'Pagamento cancelado';
    default:
      return 'Status indefinido';
  }
};

export const convertColorTripModality = (modality) => {
  if (modality == 'FRETE_NAO_RASTREAVEL') {
    return '#f1c40f';
  } else if (modality == 'FRETE_RASTREAVEL') {
    return '#bababa';
  } else if (modality == 'MANUAL') {
    return '#C71585';
  }

  return '#a885f9';
};

export const modalityIcon = (modality, typeCode, code) => {
  var iconTrip;

  switch (modality) {
    case 'MANUAL':
      iconTrip = MdSettingsSuggest;
      break;
    case 'APP_MOTORISTA':
      iconTrip = MdPhonelinkRing;
      break;
    case 'FRETE_RASTREAVEL':
      iconTrip = BiCurrentLocation;
      break;
    default:
      iconTrip = AiFillWarning;
      break;
  }

  if (modality === 'APP_MOTORISTA' && typeCode == 'OPENTECH' && code != null && code != '') {
    return (
      <Flex>
        <Icon as={BiCurrentLocation} color={convertColorTripModality('FRETE_RASTREAVEL')} w="1.1rem" h="1.1rem" />
        <Icon as={MdPhonelinkRing} color={convertColorTripModality(modality)} ml="2" w="1.1rem" h="1.1rem" />
      </Flex>
    );
  }

  return <Icon as={iconTrip} color={convertColorTripModality(modality)} w="1.1rem" h="1.1rem" />;
};

export const modalityTitle = (modality, typeCode, code) => {
  switch (modality) {
    case 'MANUAL':
      return 'Gestão manual';
    case 'APP_MOTORISTA':
      if (typeCode == 'OPENTECH' && code != null && code != '') {
        return 'Rastreável (gerenciadora de risco/app motorista)';
      }

      return 'Rastreável (app motorista)';
    case 'FRETE_RASTREAVEL':
      return 'Rastreável (gerenciadora de risco)';
    default:
      return 'Não rastreável';
  }
};

export const typeTransportTitle = (typeTransport) => {
  switch (typeTransport) {
    case 'GENERAL':
      return 'Transporte geral';
    case 'FREIGHT_RETURN':
      return 'Transporte de frete-retorno';
    case 'FRETE_RASTREAVEL':
      return 'Rastreável (gerenciadora de risco)';
    case 'PORT':
      return 'Transporte portuário';
    default:
      return 'Transporte internacional';
  }
};

export const generateLabelSelect = (value) => {
  switch (value) {
    case 'APP_MOTORISTA':
      return 'Aplicativo do motorista';
    case 'FRETE_RASTREAVEL':
      return 'Rastreável';
    case 'FRETE_NAO_RASTREAVEL':
      return 'Não rastreável';
    case 'MANUAL':
      return 'Viagem manual';
    case 'VENDEMMIA':
      return 'Vendemmia';
    case 'OUTRO':
      return 'Outro';
    case 'OPENTECH':
      return 'Código Opentech (integração para rastreio)';
    case 'NOTA_FISCAL':
      return 'Número de nota fiscal';
    case 'ORDEM_SERVICO':
      return 'Número de ordem de serviço';
    case 'OUTROS':
      return 'Outros';
    case 'GENERAL':
      return 'Transporte geral';
    case 'FREIGHT_RETURN':
      return 'Transporte de frete-retorno';  
    case 'PORT':
      return 'Transporte portuário';  
    case 'INTERNATIONAL':
      return 'Transporte internacional';  
    case 'REMOVAL':
      return 'Remoção';
    case 'DEVOLUTION':
      return 'Devolução';  
    case 'MOVEMENT':
      return 'Movimentação';  
    case 'CTE':
      return 'CT-e (Conhecimento de Transporte)';
    case 'NFSE':
      return 'NFS-e (Nota fiscal de serviços)';
    case 'DTA':
      return 'DTA (Documento de Trânsito Aduaneiro)';
    case 'DTC':
      return 'DTC (Documento de Trânsito de Container)';
    case 'PLMI':
      return 'PLMI (Protocolo de liberação de mercadoria ou bem importado)';
    case 'ROMANEIO':
      return 'Romaneio';
    default:
      return value;
  }
};

export const isCompletedTrip = (status) => {
  switch (status) {
    case 'DRAFT':
    case 'CREATED':
    case 'SENT_TO_DRIVER':
    case 'ACCEPTED_BY_DRIVER':
    case 'GOING_TO_COLLECT':
    case 'READ_TO_COLLECT':
    case 'COLLECTING':
    case 'READ_TO_TRAVEL':
    case 'TRAVELLING':
    case 'REFUSED_BY_DRIVER':
    case 'REFUSED_BY_DRIVER_HANDLED':
    case 'HALTED':
    case 'HALTED_HANDLED':
    case 'CANCELLED':
      return false;
    default:
      return true;
  }
};

export const tripColors = [
  '#4B0082',
  '#0000CD',
  '#8A2BE2',
  '#800000',
  '#800080',
  '#D2691E',
  '#1E90FF',
  '#C71585',
  '#DAA520',
  '#4682B4',
  '#FF1493',
  '#FFA500',
  '#00CED1',
  '#FF00FF',
  '#FFD700',
  '#17FFE8',
  '#FA8072',
  '#DC143C',
  '#006400',
  '#FF0000',
];

export const tripLabelsString = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';

const createWorkflowTransition = ['DRAFT', 'CREATED'];
const cancelWorkflowTransition = ['DRAFT', 'CREATED', 'SENT_TO_DRIVER', 'ACCEPTED_BY_DRIVER', 'TRAVELLING'];
const sendToDriverWorkflowTransition = ['DRAFT', 'CREATED', 'REFUSED_BY_DRIVER', 'HALTED'];
const refusedWorkflowTransition = ['SENT_TO_DRIVER', 'ACCEPTED_BY_DRIVER'];
const refusedHandledWorkflowTransition = ['SENT_TO_DRIVER', 'ACCEPTED_BY_DRIVER'];
const acceptedWorkflowTransition = ['SENT_TO_DRIVER', 'CREATED'];
const haltTravelWorkflowTransition = ['GOING_TO_COLLECT', 'READ_TO_COLLECT', 'COLLECTING', 'READ_TO_TRAVEL', 'TRAVELLING'];
const haltTravelHandledWorkflowTransition = ['GOING_TO_COLLECT', 'READ_TO_COLLECT', 'COLLECTING', 'READ_TO_TRAVEL', 'TRAVELLING'];
const goToCollectionWorkflowTransition = ['ACCEPTED_BY_DRIVER'];
const arriveAtCollectionWorkflowTransition = ['GOING_TO_COLLECT'];
const startToCollectWorkflowTransition = ['READ_TO_COLLECT'];
const collectedWorkflowTransition = ['COLLECTING'];
const startTravelWorkflowTransition = ['CREATED', 'READ_TO_TRAVEL', 'ACCEPTED_BY_DRIVER', 'TRAVELLING'];
const arriveWorkflowTransition = ['TRAVELLING'];
const startUnloadWorkflowTransition = ['ARRIVED_AT_DESTINATION'];
const refuseDeliverWorkflowTransition = ['ARRIVED_AT_DESTINATION', 'UNLOADING'];
const deliverWithSignatureWorkflowTransition = ['UNLOADING'];
const deliverWithoutSignatureWorkflowTransition = ['UNLOADING'];
const completeTravelWorkflowTransition = ['DELIVERED_WITH_SIGNATURE', 'DELIVERED_WITHOUT_SIGNATURE', 'TRAVELLING'];
const allowPaymentWorkflowTransition = ['COMPLETED'];
const payWorkflowTransition = ['PAYMENT_ALLOWED'];
const preventPayWorkflowTransition = ['COMPLETED', 'PAYMENT_ALLOWED'];
const refusedDraftWorkflowTransition = ['REFUSED_BY_DRIVER'];

export const getStatusTransition = (currentStatus, targetStatus) => {
  if (createWorkflowTransition.includes(currentStatus) && targetStatus === 'CREATED') {
    return 'create';
  }

  if (cancelWorkflowTransition.includes(currentStatus) && targetStatus === 'CANCELLED') {
    return 'cancel';
  }

  if (sendToDriverWorkflowTransition.includes(currentStatus) && targetStatus === 'SENT_TO_DRIVER') {
    return 'send_to_driver';
  }

  if (refusedWorkflowTransition.includes(currentStatus) && targetStatus === 'SENT_TO_DRIVER') {
    return 'refused';
  }

  if (refusedHandledWorkflowTransition.includes(currentStatus) && targetStatus === 'SENT_TO_DRIVER') {
    return 'refused_handled';
  }

  if (acceptedWorkflowTransition.includes(currentStatus) && targetStatus === 'ACCEPTED_BY_DRIVER') {
    return 'accepted';
  }

  if (haltTravelWorkflowTransition.includes(currentStatus) && targetStatus === 'HALTED') {
    return 'halt_travel';
  }

  if (haltTravelHandledWorkflowTransition.includes(currentStatus) && targetStatus === 'HALTED') {
    return 'halt_travel_handled';
  }

  if (goToCollectionWorkflowTransition.includes(currentStatus) && targetStatus === 'GOING_TO_COLLECT') {
    return 'go_to_collection';
  }

  if (arriveAtCollectionWorkflowTransition.includes(currentStatus) && targetStatus === 'READ_TO_COLLECT') {
    return 'arrive_at_collection';
  }

  if (startToCollectWorkflowTransition.includes(currentStatus) && targetStatus === 'COLLECTING') {
    return 'start_to_collect';
  }

  if (collectedWorkflowTransition.includes(currentStatus) && targetStatus === 'READ_TO_TRAVEL') {
    return 'collected';
  }

  if (startTravelWorkflowTransition.includes(currentStatus) && targetStatus === 'TRAVELLING') {
    return 'start_travel';
  }

  if (arriveWorkflowTransition.includes(currentStatus) && targetStatus === 'ARRIVED_AT_DESTINATION') {
    return 'arrive';
  }

  if (startUnloadWorkflowTransition.includes(currentStatus) && targetStatus === 'UNLOADING') {
    return 'start_unload';
  }

  if (refuseDeliverWorkflowTransition.includes(currentStatus) && targetStatus === 'DELIVER_REFUSED') {
    return 'refuse_deliver';
  }

  if (deliverWithSignatureWorkflowTransition.includes(currentStatus) && targetStatus === 'DELIVERED_WITH_SIGNATURE') {
    return 'deliver_with_signature';
  }

  if (deliverWithoutSignatureWorkflowTransition.includes(currentStatus) && targetStatus === 'DELIVERED_WITHOUT_SIGNATURE') {
    return 'deliver_without_signature';
  }

  if (completeTravelWorkflowTransition.includes(currentStatus) && targetStatus === 'COMPLETED') {
    return 'complete_travel';
  }

  if (allowPaymentWorkflowTransition.includes(currentStatus) && targetStatus === 'PAYMENT_ALLOWED') {
    return 'allow_payment';
  }

  if (payWorkflowTransition.includes(currentStatus) && targetStatus === 'PAID') {
    return 'pay';
  }

  if (preventPayWorkflowTransition.includes(currentStatus) && targetStatus === 'PAYMENT_CANCELLED') {
    return 'prevent_pay';
  }

  if (refusedDraftWorkflowTransition.includes(currentStatus) && targetStatus === 'DRAFT') {
    return 'refused_draft';
  }

  return '';
};
