import { memo, useCallback, useEffect, useRef } from 'react';

import { useLoadScript } from '@react-google-maps/api';
import { useFormikContext } from 'formik';

import { DynamicInputField } from './DynamicInputField';

/**
 * Componente para renderizar um campo de input com Autocomplete de endereço usando a Google Maps API,
 * integrado com Formik.
 *
 * @param {Object} props - Propriedades do componente.
 * @param {string} name - Nome do campo dentro do Formik.
 * @param {function} setFieldValue - Função do Formik para atualizar o valor do campo.
 */
const MemoizedAddressAutocomplete = ({ props }) => {
  const { setFieldValue } = useFormikContext();
  const { name } = props;
  const inputRef = useRef(null);

  // Carrega o script da Google Maps API
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_KEY,
    libraries: ['places'],
  });

  // Função de callback para configurar o autocomplete
  const initializeAutocomplete = useCallback(() => {
    if (inputRef.current) {
      const autocomplete = new window.google.maps.places.Autocomplete(inputRef.current, {
        types: ['address'],
        componentRestrictions: { country: 'br' }, // Restringe para endereços no Brasil
      });

      autocomplete.addListener('place_changed', () => {
        const place = autocomplete.getPlace();
        if (place?.formatted_address) {
          setFieldValue(name, place.formatted_address);
        }
      });
    }
  }, [name, setFieldValue]);

  // Inicializa o Autocomplete quando o script estiver carregado
  useEffect(() => {
    if (isLoaded) {
      initializeAutocomplete();
    }
  }, [isLoaded, initializeAutocomplete]);

  if (loadError) return <p>Erro ao carregar Google Maps</p>;
  if (!isLoaded) return <p>Carregando...</p>;

  return <DynamicInputField props={{ ref: inputRef, autocomplete: true, ...props }} />;
};

// Exporta o componente memorizado para evitar renderizações desnecessárias
export const DynamicAddressAutocomplete = memo(MemoizedAddressAutocomplete);
