import React from 'react';

import { Box, Collapse, Divider, Flex, Image, Text } from '@chakra-ui/react';

import cte from '../../../../../assets/svgs/cte.svg';
import ProgressBar from '../../../../../components/ProgressBar/ProgressBar';

const CteDetails = ({ isOpenCollapse, trip, loadData }) => {
  return (
    <Collapse in={isOpenCollapse} animateOpacity>
      <Flex bgColor="#F9F9F9" direction="column" w="full">
        {trip?.timeline?.length > 0 &&
          trip.timeline.map((item, index) => (
            <>
              {item?.cte && (
                <Flex gap={'15px'} pl="20px" pt="10px">
                  <Image src={cte} />

                  <Text textStyle="tableTitle" fontWeight="medium" color="#422C76" fontSize="1.3rem">
                    {item?.cte && item?.cte}
                  </Text>
                </Flex>
              )}

              <Box key={index} pl="20px" pt="20px" pb="20px" w="100%">
                <ProgressBar timeline={item} />
              </Box>

              {index < trip?.timeline?.length - 1 && <Divider mt="10px" />}
            </>
          ))}
      </Flex>
    </Collapse>
  );
};

export default CteDetails;
