// Constantes para as máscaras comuns
const MASKS = {
  documentNumber: '999.999.999-99', // CPF
  phone: '(99) 9999-9999', // Telefone fixo
  cellphone: '(99) 99999-9999', // Telefone celular
  BIRTHDAY: '99/99/9999', // Data de nascimento
  ADDRESS_ZIPCODE: '99999-999', // CEP
  cnpj: '99.999.999/9999-99', // CNPJ
  creditCard: '9999 9999 9999 9999', // Número de cartão de crédito
  date: '99/99/9999', // Data (genérica)
  time: '99:99', // Hora
  datetime: '99/99/9999 99:99', // Data e hora
  currency: 'R$ 999.999.999,99', // Valor monetário
  percentage: '999%', // Percentual
};

// Função para retornar a máscara correspondente a uma chave
export const getMaskByKey = (key) => MASKS[key] || null;
